import { WeExplain } from 'src/app/classes/we-explain';
import { WeComplete } from 'src/app/classes/we-complete';
import { WeChoose } from 'src/app/classes/we-choose';

export class QuestionList {
    weComplete: WeComplete;
    weExplain: WeExplain;
    weChoose: WeChoose;
    /**
     *
     */
    constructor() {
        this.weComplete = new WeComplete();
        this.weExplain = new WeExplain();
        this.weChoose = new WeChoose();
    }



}
