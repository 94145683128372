

export class WeComplete {
    whatToImprove: string;
    whyLA: string;
    howIncorporateCommunityFootprint: string;
    checkInTimeVendorRoles: string;
    whatElementsToRaiseBar: string;
    whatComponentsBetterBudget: string;

    constructor() {
         

    }

}

