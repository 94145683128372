import { YesNoCommentBlock } from './yes-no-comment-block';

export class WeChoose {
    whichSuperhero: string;
    whenBurnCandles: string;
    currentCheckinSystemIsProprietary: YesNoCommentBlock;
    importantMessageTieIns: boolean;
    importantMessageGreen: boolean;
    importantMessagePhilantropy: boolean;
    importantMessageFoodAndBeverage: boolean;
    
    constructor() {
        this.currentCheckinSystemIsProprietary = new YesNoCommentBlock();

    }
    



}
