import { Component, OnInit } from '@angular/core';
import { QuestionList } from '../../classes/question-list';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

   Q: QuestionList;
   url: string;
   forceRemote = false;
   submitStarted = false;
   
  constructor(
    private http: HttpClient,
    private router: Router) {
      this.Q = new QuestionList();


      this.forceRemote = true;
      if (window.location.hostname=="localhost" && !this.forceRemote ) { this.url = "http://localhost:50345/api/weco"; }
      else { this.url = 'https://weco-rfp.totalactivations.com/back/api/weco'; }
      console.log(`Running on ${window.location.hostname}, setting url to ${this.url}` );
  }

  ngOnInit() {
  }

  submit() {
    
    this.submitStarted =  true;

    this.http.post(this.url, this.Q).subscribe(
      data  => {
        this.router.navigateByUrl('/thankyou');
      }
    );    
  }
}
