import {YesNoCommentBlock} from './yes-no-comment-block';

export class WeExplain {
    FeedbackForThisYear: YesNoCommentBlock;
    InspiringMessage: YesNoCommentBlock;
    LastingImpressionOnHostCity: YesNoCommentBlock;
    UseLocalDMC: YesNoCommentBlock;
    LocalDMCSecured: YesNoCommentBlock;
 
    ReceivedExhibitorManual: boolean;
    HaveWorkedWithThisUnion: boolean;
    UpperMgmtRelationships: boolean;
    MetWithUnionSupervisors: boolean;
    InternetWasGoodLastTime: boolean;
    

    constructor() {
        this.FeedbackForThisYear = new YesNoCommentBlock();
        this.InspiringMessage = new YesNoCommentBlock();
        this.LastingImpressionOnHostCity = new YesNoCommentBlock();
        this.UseLocalDMC = new YesNoCommentBlock();
        this.LocalDMCSecured = new YesNoCommentBlock();

    }
}
